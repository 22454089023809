import useMouseOver from "@/hooks/useMouseOver";
import { useScrollMobile } from "@/hooks/useScroll";
import generateUniqueEventID from "@/lib/generateUniqueEventID";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import styles from "@/styles/ctaForm.module.css";
import GetStartedModal from "@/components/GetStartedModal";
import * as fbq from "@/lib/fpixel"
import axios from "axios";
import useClientIP from "@/hooks/useClientIP";
import { v4 as uuidv4 } from 'uuid';

const CtaForm = () => {
  const isScroll = useScrollMobile();
  const [showModal, setShowModal] = useState(false);
  const isPixelActive = useMouseOver();
  const router = useRouter();
  const uniqueEventID = generateUniqueEventID();
  const [pixelFired, setPixelFired] = useState(false);

  const qParams = router.query.ctaForm;
  const ipAddress = useClientIP();
  const handleButtonClick = () => {
    const newParams = { ctaForm: "clicked" };
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("ctaForm", newParams.ctaForm);
    window.history.pushState({}, "", currentUrl);
  };

  function getClickId() {
    // Check for _fbc cookie
    const fbcCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbc='));
  
    if (fbcCookie) {
      console.log('Returning _fbc from cookie:', fbcCookie.split('=')[1]);
      return fbcCookie.split('=')[1];
    } else {
      console.warn('No _fbc cookie found');
    }
  
    // Generate _fbc from fbclid query parameter if _fbc cookie does not exist
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    if (fbclid) {
      const subdomainIndex = 1; // Adjust this based on your domain structure
      const creationTime = Math.floor(new Date().getTime() / 1000); // Use current time as creation time
      const generatedFbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
      console.log('Generated _fbc from fbclid:', generatedFbc);
      return generatedFbc;
    } else {
      console.warn('No fbclid query parameter found');
    }
  
    // Generate a fallback _fbc value
    const subdomainIndex = 1;
    const creationTime = Math.floor(new Date().getTime() / 1000);
    const placeholderFbclid = '0000000000000000'; // Placeholder if no fbclid is found
    const fallbackFbc = `fb.${subdomainIndex}.${creationTime}.${placeholderFbclid}`;
    console.log('Generated fallback _fbc:', fallbackFbc);
    return fallbackFbc;
  }

  function getBrowserId() {
    // Logic to get Facebook Browser ID (fbp)
    const fbpCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbp='));
    return fbpCookie ? fbpCookie.split('=')[1] : undefined;
  }
  async function sendViewContentEvent(eventName, customData, uniqueEventID) {
    const userAgent = navigator.userAgent;
      const clickId = getClickId(); // Replace with your actual click ID logic
      const browserId = getBrowserId(); // Function to get Facebook Browser ID (fbp)
      const externalId = uuidv4(); // Replace with your actual external ID logic
      const ipaddress = ipAddress; 
    
    try {
      const response = await axios.post('/api/send-eventcontent', {
        eventName,
        customData,
        uniqueEventID,
        clickId,
        userAgent,
        browserId,
        externalId,
        ipAddress:ipaddress
      });
  
      console.log('ViewContent event sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending ViewContent event:', error.response ? error.response.data : error.message);
    }
  }

  useEffect(() => {
    if (qParams === "clicked") {
      setShowModal(true);
    }
  }, [qParams]);



  const sendClientSideTracking = () => {
    if (
      !pixelFired &&
      process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_CONTROLER === "true" &&
      isPixelActive
    ) {

sendViewContentEvent('Contact', {
  page_title: router.pathname,
}, uniqueEventID);


     // fbq.event(
      //  "Contact",
      //  {
      //    page_title: router.pathname,
      //  },
      //  uniqueEventID,
     // );
     console.log("Cta event")
      setPixelFired(true);
    }
  };

  const toggleModal = () => {
    handleButtonClick();
    setShowModal(!showModal);

    setTimeout(() => {
      sendClientSideTracking();
    }, 1000 * 0.5);
  };
  return (
    <>
      {isScroll ? (
        <div className={styles["sticky-cta-button"]}>
          <div onClick={toggleModal}>
            <p>GET STARTED NOW</p>
          </div>
          <GetStartedModal openModal={showModal} setOpenModal={setShowModal} />
        </div>
      ) : null}
    </>
  );
};

export default CtaForm;
