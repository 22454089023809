import dynamic from "next/dynamic";
import { useMediaQuery } from "@mui/material";
import CtaForm from "@components/cta/CtaForm";
import CtaWPButton from "@components/cta/CtaWPButton";

const HeroForm = dynamic(() => import("./HeroForm"), {
  ssr: true,

  loading: () => <p className="text-center text-white">Loading...</p>,
});

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";

import useMouseOver from "@/hooks/useMouseOver";
import starWarsData from '../../data/starWarsData.json'
import Header from "../header/Header";
const HeroSection02 = ({ heroData }) => {
  const isMobile = useMediaQuery("(max-width:820px)");
  const { hero_content } = heroData;

  const { desktop_ph_alt, mobile_ph_alt, desktop_video, mobile_video } =
    hero_content;
    const mobile_logo = starWarsData?.logo.logo_content.mobile_logo_video;
    const desktop_logo = starWarsData.logo.logo_content.desktop_logo_video;
  const heroSliderMobile = [
    {
      title: "GET YOUR AMAZING APP",
      desc: "Transforming visions into apps.",
      imageUrl: "/app.webp",
    },
    {
      title: "GET YOUR AMAZING Website",
      desc: "Result-Oriented Web Design.",
      imageUrl: "/web.webp",
    },
    {
      title: "GET YOUR AMAZING Logo",
      desc: "Strategic Design, Lasting Impact.",
      imageUrl: "/graphics.webp",
    },
  ];

  // const d_video = desktop_video ? desktop_video : "/hero-section-video.mp4";

  const d_alt = desktop_ph_alt
    ? desktop_ph_alt
    : "Tall building in Nanjing city";

  const m_video = mobile_video
    ? mobile_video
    : "https://customer-xsqikd9wak0sq9wp.cloudflarestream.com/b414761c4f6336a7b9b3c5d49cf87b40/downloads/default.mp4";
  const m_alt = mobile_ph_alt ? mobile_ph_alt : "Tall building in Nanjing city";
  const isActive = useMouseOver();

  return (
    <>

      <div className="bg-white h-auto relative">
        {!isMobile ? (
          <div className="relative ">
            <div className="desktop-hero-section-second">
              <video
                width={1920}
                priority="true"
                height={1080}
                quality={100}
                src="/hero-section-video.mp4"
                muted
                playsInline
                autoPlay
                loop
                alt={d_alt}
                className={`hidden sm:block z-[-1] flex justify-center  min-w-[100vw] `}
                poster="/hero-section-video.mp4"
              />
            </div>
            <div className="absolute top-0 w-[100%]">
              <HeroForm isAnimate={true} isVisible={true} />
            </div>
          </div>
        ) : (
          <> 
                {/*      <img
                      fetchPriority="high"
                      rel="preload"

                  
src="/app.webp"
alt={m_alt}
priority={false}

className="w-[300px] h-[300px] sm:w-[400px] sm:h-[400px] "

        /> */}

          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            slidesPerGroup={1}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1120: {
                slidesPerView: 3,
              },
              2000: {
                slidesPerView: 3,
              },
            }}
          >
            

            {heroSliderMobile.map((item, index) => (
              <>
              
              
              <SwiperSlide key={index}>


              <img
                      fetchPriority="high"
                      rel="preload"

                  
src={item.imageUrl}
alt={m_alt}
priority={false}

className="w-[300px] h-[300px] sm:w-[400px] sm:h-[400px] "

/>

                {/*<Image
                  width={700}
                  height={580}
                  quality={100}
                  
                  src={item.imageUrl}
                  alt={m_alt}
                  priority={false}
                  poster={item.imageUrl}
                  className=" w-100% h-auto hero-banner-second"
                  
            /> */}
                <div className="hero-banner-text">
                  <p> {item.title} </p>
                  <p> {item.desc} </p>
                </div>
              </SwiperSlide>
              </>
            ))}
          </Swiper>
          </>
        )}

        {/* <HeadLine /> */}
      </div>

      <div className="hero-section-container-main sm:hidden" />
      {isActive && (
        <>
          <CtaWPButton />
          <CtaForm />
        </>
      )}

      <style jsx global>{`
.hero-banner-second {
  min-height: calc(30vh - 200px);
  max-height: calc(80vh - 200px);
  margin-bottom: calc(100vh - 99.5vh);
  aspect-ratio: 3/3;
  object-fit: contain;
}

.hero-banner-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: var(--padding-text-mobile-home);
}
.hero-banner-text p:first-child {
  font-size: 26px;
  font-weight: 600;
}
.hero-banner-text p:last-child {
  padding-top: 10px;
  max-width: calc(100vw - 20vw);
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .hero-banner-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: var(--padding-text-mobile-home);
  }
  .hero-banner-text p:first-child {
    font-size: 19px;
    font-weight: 600;
  }
  .hero-banner-text p:last-child {
    padding-top: 10px;
    max-width: calc(100vw - 20vw);
    font-size: 15px;
    font-weight: 400;
  }
}

}

   `}</style>
    </>
  );
};

export default HeroSection02;
