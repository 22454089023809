import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

const useScroll = (inView, m_thresh_hold) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (inView) {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const minThreshold = m_thresh_hold; // adjust this value as needed

        if (scrollTop > minThreshold) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [inView, m_thresh_hold]);

  return isScrolled;
};

const useScrollMobile = () => {
  const isMobile = useMediaQuery("(max-width:820px)");

  const [isScrolledMobile, setIsScrolledMobile] = useState(false);

  useEffect(() => {
    const checkIsScrolledMobile = () => {
      if (window.scrollY >= window.innerHeight * 1) {
        setIsScrolledMobile(true);
      } else {
        setIsScrolledMobile(false);
      }
    };

    if (isMobile) {
      window.addEventListener("scroll", checkIsScrolledMobile);
    }
    return () => {
      window.removeEventListener("scroll", checkIsScrolledMobile);
    };
  }, [isMobile]);

  return isScrolledMobile;
};

export { useScroll, useScrollMobile };
