import { useState, useEffect } from "react";
import styles from "@/styles/ctaWp.module.css";

const CtaWPButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleScroll = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles["sticky-phone-button-wp"]}>
      <a
        href={`https://wa.me/18326153920`}
        className="flex items-center group"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setIsHovered(true)}
        style={{ width: isHovered ? "320px" : "70px" }} // Adjust the widths as needed
      >
        <img
          src="/wp-icon-cta.webp"
          className="w-8 h-8 transform transition-transform duration-300 " alt="ctabutton"
        />
        <p className=" opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          +1 832 615 3920
        </p>
      </a>
    </div>
  );
};

export default CtaWPButton;
