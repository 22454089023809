import React from "react";

// import HeroSection01 from './HeroSection01';
import HeroSection02 from "./HeroSection02";

const HeroSection = ({ selected, heroData }) => {
  switch (selected) {
    case 1:
      return <HeroSection02 heroData={heroData} />;
      
    case 2:
      return <HeroSection02 heroData={heroData} />;
      
    default:
      return <HeroSection02 heroData={heroData} />;
      
  }
};

export default HeroSection;
