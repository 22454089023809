import Head from "next/head";
import { NextSeo } from "next-seo";
import React from "react";

import {
  FaqDynamic,
  OurPartners,
  PortfolioSectionHomePage,
  ServicesC,
  TransformBrand,
} from "../../components/HomePageC";

import HeroSection from "./../../components/hero-section/index";
import dynamic from "next/dynamic";

const Form = dynamic(() => import("../../components/Form"), {
  ssr: true,

  loading: () => <p className="text-center text-white">Loading...</p>,
});

const SeoComponents = ({ seoData }) => {
  const { canonical, jsonLtd, metaDescription } = seoData;
  return (
    <Head>
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={canonical} />
      <meta name="description" content={metaDescription} />
      <link rel="apple-touch-icon" href="apple-touch-icon.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: jsonLtd }}
      />
    </Head>
  );
};

export default function HomePage01({ pageData }) {
  if (!pageData) {
    return (
      <div className="h-screen w-full flex justify-center items-center bg-black text-white">
        Loading....
      </div>
    );
  }

  const { seo, pageBuilder } = pageData;

  const {
    hero,
    servicesOffer,
    officeSlider,
    homePortfolio,
    ourPartners,
    faqs,
  } = pageBuilder;

  return (
    <div className="bg-black">
      <NextSeo
        title={seo?.metaTitle}
        additionalLinkTags={[
          {
            rel: "icon",
            href: "/favicon.ico",
          },
          {
            rel: "apple-touch-icon",
            href: "https://www.test.ie/touch-icon-ipad.jpg",
            sizes: "76x76",
          },
        ]}
        openGraph={{
          image: "/favicon.ico",
        }}
      />
      <SeoComponents seoData={seo} />

      <section className="w-full -mt-1 sm:mt-0 ">
        <HeroSection selected={2} heroData={hero} />
      </section>

      <main className="mx-auto overflow-x-hidden  ">
        <section
          style={{ "--bg-02": "black" }}
          className="body-top-section-rotate  min-h-screen  sm:mt-0  "
        >
          <ServicesC serviceData={servicesOffer} />

          <div className="hero-section-container-transform-brand-headline " />
        </section>

        <section className="body-top-section-rotate-3-white -mt-[1px]">
          <TransformBrand data={officeSlider} />
        </section>

        <section
          style={{ "--bg-02": "black" }}
          className="body-top-section-rotate  w-full  py-3"
        >
          <PortfolioSectionHomePage data={homePortfolio} />
          <div className="hero-section-container-transform-brand -mb-[0.5px]" />
          <div className="partners-home-container">
            <OurPartners data={ourPartners} />
            <style jsx>
              {`
                .partners-home-container {
                  background: white;
                  height: calc(100vh - 40vh);
                  display: flex;
                }
              `}
            </style>
          </div>
          <div className="hero-section-container" />
        </section>

        <section
          style={{ "--website-bg-02": "black" }}
          className="body-top-section-rotate-3 py-[2rem] -mt-[5rem]"
        >
          <Form isAnimate={true} isVisible={true} />
          <FaqDynamic faq={faqs} />
        </section>
      </main>
    </div>
  );
}
